// Required
@import '../../node_modules/bootstrap/scss/functions';

// Default variable overrides
$body-bg: white;
$body-color: black;

// Required
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

// Optional Bootstrap components here
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/forms';
// etc

@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/bootstrap-utilities.scss';

.input-group-text,
.form-control {
    border-radius: 100px;
    height: 3em;
    font-size: 1.2rem;
}

.form-control {
    box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

.form-control:focus {
    border: none;
    background-color: unset;
    border: 0.1px solid #666;
    box-shadow: none;
}

.form-control[placeholder]:focus {
    border: none;
    background-color: unset;
    outline: unset;
    border: 0.1px solid #ff0000 !important;
    box-shadow: none;
}

.input-group-text {
    background-color: #aeaeae;
    font-weight: bold;
    width: 5em;
    justify-content: center;
}

.form-control::placeholder {
    color: red;
}
.form-control[placeholder] {
    box-shadow: inset 0px 0px 20px 0px rgba(255, 0, 0, 0.132);
}

p {
    margin-bottom: 0;
}

h4 {
    font-weight: bold;
    font-size: 1.5rem;
}
