main {
    width: 100vw;
    height: 100svh;
    overflow: hidden;
}

canvas {
    width: 100%;
    height: 100%;
}

button {
    display: block;
    color: black;
    padding: 0.5em;
}

.container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    width: 100%;
    margin: 0.5em 0;
    display: flex;
    gap: 0.5em;
    justify-content: center;
}
